import React, { useCallback } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Components
import Layout from '../../components/layout/Layout';
import ServicesSection from '../../components/section/Services';
import PlansSection from '../../components/section/Plans';
import TestimonialsSection from '../../components/section/Testimonials';
import RealisationsSection from '../../components/section/Realisations';
import ToolsSection from '../../components/section/Tools';
import ContactForm from '../../components/form/Contact';

// Images
import headerBackgroundImage from '../../images/svg/components/wave-pattern-light.svg';

const IndexLocalBusinessTemplate = ({ data }) => {

  const { t } = useTranslation();
  const { language } = useI18next();

  const location = useLocation();
  const origin = location.origin;

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  const pageImage = {
    alt: t('image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })
  };

  const pageSchemas = [
    {
      type: "breadcrumb",
      listItems: [
        {
          name: t('breadcrumb.cities'),
          item: origin + (language === 'fr-fr' ? `/` : `/${language}/`) + "cities/"
        },
        {
          name: t('meta.title', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })
        },
      ]
    }
  ];

  return (
    <Layout
      pageType="custom"
      pageTitle={t('meta.title', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light"
      headerStyle="light"
    >
      <div>
        <div className="gradient-y-gray position-relative" data-activity-plugin="aos">
          <div className="content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-5">
            <div className="container">
              <div className="row justify-content-md-between align-items-sm-center d-none d-md-flex">
                <div className="col-sm-6">
                  <div className="mb-5">
                    <h1 className="h1 display-4 mb-3" dangerouslySetInnerHTML={{ __html: t('title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h1>
                    <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                  </div>
                  <div className="d-grid d-sm-flex gap-3">
                    <Link className="btn btn-primary btn-transition px-6" to="/contact/">{t('cta.contactUs')}</Link>
                    <AnchorLink className="btn btn-link" href="#services">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
                <div className="col-8 col-sm-6 mb-5 mb-sm-0">
                  <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/local-business/index/la-small-agency-index.svg" alt={t('image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                </div>
              </div>
              <div className="row justify-content-md-between align-items-sm-center d-md-none d-flex">
                <div>
                  <div className="position-relative mb-3">
                    <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/local-business/index/la-small-agency-index.svg" alt={t('image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  </div>
                  <div className="mb-5">
                    <div className="h1 display-4 mb-3" dangerouslySetInnerHTML={{ __html: t('title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></div>
                    <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                  </div>
                  <div className="d-grid d-sm-flex gap-3">
                    <Link className="btn btn-primary btn-transition px-6" to="/contact/">{t('cta.contactUs')}</Link>
                    <AnchorLink className="btn btn-link" href="#services">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1" style={{ marginBottom: '-.125rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
            </svg>
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3" id="services">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('services.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
            <p>{t('services.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
          </div>
          <ServicesSection data={data} service="local_business_index" />
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true">
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-discovery" id="process-discovery-tab" data-bs-toggle="tab" data-bs-target="#process-discovery" role="tab" aria-controls="process-discovery" aria-selected="false">
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.discovery.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.discovery.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-plan" id="process-plan-tab" data-bs-toggle="tab" data-bs-target="#process-plan" role="tab" aria-controls="process-plan" aria-selected="false">
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="#035A4B"></path>
                          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.plan.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.plan.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-implementation" id="process-implementation-tab" data-bs-toggle="tab" data-bs-target="#process-implementation" role="tab" aria-controls="process-implementation" aria-selected="false">
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.implementation.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.implementation.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-optimization" id="process-optimization-tab" data-bs-toggle="tab" data-bs-target="#process-optimization" role="tab" aria-controls="process-optimization" aria-selected="false">
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z" fill="#035A4B"></path>
                          <path d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.optimization.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.optimization.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/index/la-small-agency-process-conversation.svg" alt={t('process.conversation.image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  <p className="text-center">{t('process.conversation.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-discovery" role="tabpanel" aria-labelledby="process-discovery-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/index/la-small-agency-process-discovery.svg" alt={t('process.discovery.image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  <p className="text-center">{t('process.discovery.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-plan" role="tabpanel" aria-labelledby="process-plan-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/index/la-small-agency-process-plan.svg" alt={t('process.plan.image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  <p className="text-center">{t('process.plan.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-implementation" role="tabpanel" aria-labelledby="process-implementation-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/index/la-small-agency-process-implementation.svg" alt={t('process.implementation.image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  <p className="text-center">{t('process.implementation.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-optimization" role="tabpanel" aria-labelledby="process-optimization-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/index/la-small-agency-process-optimization.svg" alt={t('process.optimization.image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })} />
                  <p className="text-center">{t('process.optimization.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="position-relative overflow-hidden content-space-3">
          <div className="position-absolute top-50 start-50 translate-middle w-100">
            <div className="container-fluid px-lg-6">
              <div className="row justify-content-center">
                <div className="col-3 my-4">
                  <div className="d-none d-md-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/google-icon.svg" alt={t('solutions.google')} />
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="d-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/paypal-icon.svg" alt={t('solutions.paypal')} />
                  </div>
                </div>
                <div className="col-3 my-4">
                  <div className="d-none d-md-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/squarespace-icon.svg" alt={t('solutions.squarespace')} />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-3 mb-4">
                  <div className="d-block avatar avatar-xl rounded-circle shadow-sm p-4" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/slack-icon.svg" alt={t('solutions.slack')} />
                  </div>
                </div>
                <div className="col-3 my-4">
                  <div className="d-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/trustpilot-icon.svg" alt={t('solutions.trustpilot')} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 offset-1 my-4">
                  <div className="d-none d-md-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/facebook-icon.svg" alt={t('solutions.facebook')} />
                  </div>
                </div>
                <div className="col-3 offset-2 my-4">
                  <div className="d-none d-md-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/hubspot-icon.svg" alt={t('solutions.hubspot')} />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-4">
                  <div className="d-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/prestashop-icon.svg" alt={t('solutions.prestashop')} />
                  </div>
                </div>
                <div className="col-4 mt-6">
                  <div className="d-none d-sm-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/google-shopping-icon.svg" alt={t('solutions.googleShopping')} />
                  </div>
                </div>
                <div className="col-4 mt-6">
                  <div className="d-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto" data-aos="zoom-in" data-aos-offset="-150">
                    <StaticImage imgClassName="avatar-img" objectFit="contain" quality="30" src="../../images/brands/mailchimp-icon.svg" alt={t('solutions.mailchimp')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container position-relative zi-1 content-space-3 content-space-sm-5">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto">
              <h2>{t('solutions.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
              <p>{t('solutions.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
              <AnchorLink className="link" href="#plans">{t('cta.discoverPricing')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
            </div>
          </div>
          <div className="gradient-y-lg-white position-absolute top-0 start-0 w-100 h-100"></div>
        </div>
        <div className="bg-light">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('plans.title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
              <p>{t('plans.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
            </div>
            <PlansSection data={data} plan={data.plan.frontmatter} service="local_business_index" />
          </div>
        </div>
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="local_business_index" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="local_business_index" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 text-center mx-md-auto mb-5">
            <span className="text-cap">{t('tools.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</span>
            <h2>{t('tools.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
          </div>
          <ToolsSection data={data} service="local_business_index" />
        </div>
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="bg-dark rounded-2" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} >
            <div className="px-4 px-md-8 px-lg-10">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-5 col-md-10  mb-9 mt-9 mb-lg-0 mt-lg-0">
                  <h1 className="text-white">{t('contact.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h1>
                  <p className="text-white-70">{t('contact.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                  <div className="border-top border-white-10 my-5" style={{ maxWidth: '10rem' }}></div>
                  <div className="row">
                    {data.site.siteMetadata.telephone ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-telephone-inbound-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.callUs')}</h4>
                        <a className="text-white-70 small" href={"tel:" + data.site.siteMetadata.telephoneIndex}>{data.site.siteMetadata.telephone}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.email ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-envelope-open-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.emailUs')}</h4>
                        <a className="text-white-70 small" href={"mailto:" + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.addressFull ?
                      <div className="col-sm-6">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-geo-alt-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.visitUs')}</h4>
                        <a className="text-white-70 small" href={"http://maps.google.com/maps?daddr=" + data.site.siteMetadata.addressLatitude + "%2C" + data.site.siteMetadata.addressLongitude} target="_blank" rel="noopener noreferrer">{data.site.siteMetadata.addressFull}</a>
                      </div>
                      : null}
                  </div>
                </div>
                <div className="col-lg-7 mb-9 mt-9 mb-lg-n10 mt-lg-n10">
                  <div className="card card-lg">
                    <div className="card-header bg-light text-center">
                      <h3 className="card-title">{t('contact.text')}</h3>
                    </div>
                    <div className="card-body">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

IndexLocalBusinessTemplate.propTypes = {
  pageContext: PropTypes.shape({
    name_slug: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
      name_slug: PropTypes.string.isRequired,
    }).isRequired
  }),
}

export default IndexLocalBusinessTemplate

export const query = graphql`
  query ($name_slug: String, $language: String!) {
    site: site {
      siteMetadata {
        title
        image {
          url
          secureUrl
          type
          width
          height
        }
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    city : citiesCsv(name_slug: {eq: $name_slug}) {
      name
      name_capital
      name_lowercase
      name_slug
      department
      postal_code
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "local-business-index", "plans", "categories", "tools", "contact-form", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { home: { eq: true }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "local_business_index"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
      }
      id
    }
  }
`
